import React, { Component } from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import ReactHtmlParser from "react-html-parser"
import Link  from '../components/delayed-gatsby-link'
import MoreButton from "../components/more-button"

class Page extends Component {
  constructor(props) {
    super(props);

    this._itemClicked = this.itemClicked.bind(this);
  }

  itemClicked(event){
    window.dispatchEvent( new Event('animateOut') );
  }

  componentDidMount() {
    
  }

  getNext(){
    const post = this.props.data.wordpressWpProjects
    const data = this.props.data.allWordpressWpProjects.edges.slice(0);
    data.sort(function(a, b) {
        return a.node.menu_order - b.node.menu_order;
    });

    var firstItem;
    var lastItem;
    var isFound = false;
    var len = data.length;
    for(var i=0; i<len; i++){
      var item = data[i];
      if(i===0){
        firstItem = item;
      }

      if(lastItem && post.slug === lastItem.node.slug){
        return item.node;
      }

      lastItem = item;
    }

    if(!isFound){
      return firstItem.node;
    }
    
    return;
  }

  render() {
    const StaticPage = this.props.data.wordpressWpProjects
    let next = this.getNext();
    return (
      <div className="page Project">
        <Helmet title={StaticPage.title+' - illcreative'}> { ReactHtmlParser(StaticPage.yoast_head.replace(new RegExp('http://localhost', 'g'),'https://www.illcreative.com'))}</Helmet>
        <div className="main-content">
          <article className="main-wrap">
            <section className={'ProjectHero ColorKey no-padding-top no-padding-bottom no-padding-left no-padding-right fader ' +StaticPage.slug} key={StaticPage.slug} data-bgcolor={StaticPage.acf.primary_color}>
              <div className="inner">
                <div className="title-block">
                  <div className="text-wrap">
                    <h3 className="eyebrow">{StaticPage.title}</h3>
                    <h2 className="eyebrow-before" dangerouslySetInnerHTML={{__html: StaticPage.acf.title }}></h2>
                  </div>
                </div>
                <div className="image-block">
                  <img className="image desktop fader" src={ StaticPage.acf.preview_image.source_url } alt="{StaticPage.title}"/>
                  <img className="image mobile fader" src={StaticPage.acf.preview_image.source_url.replace('.png' , '-768x768.png')}  alt="{StaticPage.title}"/>
                </div>
              </div>
            </section>
            <section className="ColorKey proxy" data-bgcolor="#ffffff">
            </section>

            <div className="PostContent" dangerouslySetInnerHTML={{__html: StaticPage.content}}>
            </div>

            <section className="GrayWrapper no-padding-left no-padding-right">
              <section className="ProjectFeatures">
                <div dangerouslySetInnerHTML={{__html: StaticPage.acf.feature_content}} className="clearfix">
                </div>
              </section>
            </section>

            <section className="ProjectNext no-padding-bottom">
              <div className="MorePosts fader">
                <h3 className="down-arrow">Next</h3>
              </div>
              <section className="ProjectPromo small fader">
                <Link delay={1000} to={"/work/"+next.slug} className="taphover" onClick={this._itemClicked}>
                  <div className="inner" style={{ backgroundColor: next.acf.primary_color }}>
                    <div className="title-block">
                      <div className="text-wrap">
                        <h3 className="eyebrow">{next.title}</h3>
                        <h2 className="eyebrow-before" dangerouslySetInnerHTML={{__html: next.acf.title }}></h2>
                        <MoreButton/>
                      </div>
                    </div>
                    <div className="image-block">
                      <img className="image" src={next.acf.preview_image.source_url} alt={next.title}/>
                    </div>
                  </div>
                </Link>
              </section>
            </section>
          </article>
        </div>
      </div>
    )
  }
}

export default Page

export const pageQuery = graphql`
  query($id: String!) {
    wordpressWpProjects(id: { eq: $id }) {
      title
      content
      slug
      excerpt
      yoast_head
      acf {
        primary_color
        title
        client_name
        sidebar_content
        client_quote
        feature_content
        preview_image {
          source_url
          alt_text          
        }
        device_image {
          source_url
          alt_text          
        }
      }
    }
    allWordpressWpProjects {
      edges {
        node {
          id
          title
          slug
          status
          template
          menu_order
          acf {
            primary_color
            title
            client_name
            preview_image {
              source_url
              alt_text
            }
            device_image {
              source_url
              alt_text
            }
            sidebar_content
            client_quote
            feature_content
          }
        }
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`